<template>
	<div class="divisions">
		<el-row class="search">
			<el-col :span="2">
				<div class="jy_dictionary" @click="dictionShow">
					<i class="el-icon-notebook-1"></i>
				</div>
			</el-col>
		</el-row>
		<jy-table
			max-height="595"
			ref="multipleTable"
			:data="list"
			tooltip-effect="dark"
			v-loading="loading"
			:row-style="setRowStyle"
		>
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.routeName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="180" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.orgName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="dirStr" label="方向" min-width="100" fixed="left">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.dirStr"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operationDate" label="营运日期" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operationDate"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planVehicleNo" label="计划车牌号" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planVehicleNo"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualVehicleNo" label="实际车牌号" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualVehicleNo"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="planDriverName" label="计划司机" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.planDriverName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="jobNum" label="计划司机工号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.jobNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualDriverName" label="实际司机" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualDriverName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualJobNum" label="实际司机工号" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualJobNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="departureTime" label="计划时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.departureTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="beginTime" label="开始时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.beginTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="endTime" label="结束时间" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.endTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="runningTime" label="运行时长(min)" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.runningTime"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="shifts" label="班次" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.shifts"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="dumpStationNum" label="未经过站点数" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.dumpStationNum"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="setMileage" label="设定线路里程(km)" min-width="160">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.setMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="actualMileage" label="实际线路里程(km)" min-width="160">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.actualMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="runMileage" label="运行里程(km)" min-width="160">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.runMileage"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="beginTimeDevice" label="开始时间依据" min-width="150">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.beginTimeDevice"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="endTimeDevice" label="结束时间依据" min-width="150">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.endTimeDevice"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="deviceIds" label="设备ID" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.deviceIds"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="overSites" label="经过站点数" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.overSites"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="shiftStatusStr" label="班次状态" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.shiftStatusStr"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="adjReason" label="班次调整原因" min-width="120">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.adjReason"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="remark" label="备注" min-width="200">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.remark"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="operateStateStr" label="运行情况" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.operateStateStr"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="templateName" label="时间牌模板" min-width="160">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.templateName"></popover>
				</template>
			</jy-table-column>
			<jy-table-column prop="timeCardOrder" label="时间牌号" min-width="100">
				<template slot-scope="scope">
					<popover :statusList="scope.row.errors" :rowValue="scope.row.timeCardOrder"></popover>
				</template>
			</jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<track-replay ref="trackReplay"></track-replay>
		<dirctor-show ref="dirctorShow"></dirctor-show>
	</div>
</template>
<script type="text/javascript">
import popover from "@/components/pages/admin/common/popover";
import dirctorShow from "@/components/pages/admin/waybillManagement/dirctorShow";
import trackReplay from "@/components/pages/admin/waybillManagement/trackReplay/trackReplay";
export default {
	props: {
		formData: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data() {
		return {
			pageIndex: 1,
			total: 10,
			pageSize: 10,
			list: [],
			operateList: [],
			loading: false
		};
	},
	components: {
		trackReplay,
		dirctorShow,
		popover
	},
	created() {
		this.setOperateList();
		// this.getList()
	},
	activated() {
		this.setOperateList();
		// this.getList()
	},
	methods: {
		setOperateList() {
			const vm = this;
			let l = [
				{
					name: "轨迹回放",
					icon: "el-icon-edit-outline",
					fun: this.trackReplay,
					isShow() {
						return vm.btnexist("/operational/trackPlayback");
					}
				}
			];
			this.operateList = l;
		},
		getList() {
			let formData = this.formData;
			let url = "/waybillOperate/queryRealtimeVOList";
			let params = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				...formData
			};
			let urlOption = {
				err: data => {
					this.$message({
						message: data.error || data.message || data.msg,
						type: "error"
					});
					this.loading = false;
				}
			};
			this.loading = true;
			this.$http.post(url, params, urlOption).then(({ detail }) => {
				this.total = detail.total;
				this.loading = false;
				detail.list.forEach(item => {
					switch (item.shiftStatus) {
						case "1":
							item.shiftStatusStr = "计划";
							break;
						case "2":
							item.shiftStatusStr = "调整";
							break;
						case "3":
							item.shiftStatusStr = "新增";
							break;
						case "4":
							item.shiftStatusStr = "砍班";
							break;
						case "5":
							item.shiftStatusStr = "脱班";
							break;
						case "6":
							item.shiftStatusStr = "司机离职";
							break;
						case "7":
							item.shiftStatusStr = "调整";
							break;
						case "8":
							item.shiftStatusStr = "调整";
							break;
						case "9":
							item.shiftStatusStr = "恢复砍脱班";
							break;
					}
					switch (item.dir) {
						case "1":
							item.dirStr = "上行";
							break;
						case "2":
							item.dirStr = "下行";
							break;
					}
					switch (item.operateState) {
						case "0":
							item.operateStateStr = "未发车";
							break;
						case "1":
							item.operateStateStr = "已发车";
							break;
						case "2":
							item.operateStateStr = "已完成";
							break;
					}
				});
				this.list = detail.list;
			});
		},
		// 设置行内元素样式
		setRowStyle({ row }) {
			if (row.errors) {
				// return 'color:' + row.errors[0].color
				return { color: row.errors[0].color };
			}
		},
		handleSizeChange(e) {
			this.pageSize = e;
			this.getList();
		},
		handleCurrentChange(e) {
			this.pageIndex = e;
			this.getList();
		},
		// 轨迹回放
		trackReplay(option) {
			this.$refs.trackReplay.init(option, "waybill");
		},
		// 字典
		dictionShow() {
			this.$refs.dirctorShow.init();
		},
		btnexist(name) {
			const parent = this.$parent.$parent.$parent;
			return parent.btnexist(name);
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="operating_divisions">
.jy_dictionary {
	font-size: 30px;
	color: #456ea5;
	cursor: pointer;
}
</style>

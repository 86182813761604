<template>
	<div class="operating_information">
		<div class="line_box">
			<line-condition :routeId="formData.routeId" :routeName="formData.routeName"></line-condition>
		</div>
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item label="线路:" prop="routeId">
				<!-- <el-input v-model="formData.routeName" @focus="selectInstitutions"></el-input> -->
				<el-select v-model="formData.routeId" placeholder="请选择线路" @change="selectChange">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="方向:" prop="dir">
				<el-select
					v-model="formData.dir"
					placeholder="请选择"
					filterable
					clearable
					:disabled="activeName != 'operatingDivisions'"
				>
					<el-option v-for="item in directionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="车牌号:" prop="vehicleNo">
				<el-input v-model="formData.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="司机:" prop="driverName">
				<el-input v-model="formData.driverName"></el-input>
			</jy-query-item>
			<jy-query-item label="运行情况:" prop="operateState">
				<el-select v-model="formData.operateState" placeholder="请选择" filterable clearable>
					<el-option v-for="item in stateLiest[activeName]" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="营运日期:" prop="date">
				<el-date-picker v-model="formData.date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button v-if="btnexist('list') || true" type="primary" @click="oncheck">查询</el-button>
				<el-button v-if="btnexist('reset') || true" plain type="primary" @click="resetForm('checkForm')">重置</el-button>
			</template>
		</jy-query>
		<el-tabs v-model="activeName" @tab-click="tabClick">
			<!-- <el-tab-pane :label="one.label" :name="one.name" v-for="one in tabList" v-bind:key="one.name">
                <keep-alive>
                    <router-view :ref="one.name" />
                </keep-alive>
            </el-tab-pane>-->
			<el-tab-pane label="营运班次" name="operatingDivisions">
				<operating-divisions ref="operatingDivisions" :formData="formData"></operating-divisions>
			</el-tab-pane>
			<el-tab-pane label="定点专线" name="fixedLine">
				<fixed-line ref="fixedLine" :formData="formData"></fixed-line>
			</el-tab-pane>
			<el-tab-pane label="非营运班次" name="noOperatingDivisions">
				<no-operating-divisions ref="noOperatingDivisions" :formData="formData"></no-operating-divisions>
			</el-tab-pane>
		</el-tabs>
		<institutions-tree
			preSelect
			ref="institutions"
			@addInstitutions="addInstitutionsLine"
			url="/waybillOperate/getTree"
			title="选择路线"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
import operatingDivisions from "./operatingDivisions.vue";
import fixedLine from "./fixedLine.vue";
import noOperatingDivisions from "./noOperatingDivisions.vue";

import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import lineCondition from "@/components/pages/admin/operationalInformation/lineCondition/index";

import { btnMixins } from "@/common/js/button.mixin";
const directionList = [
	{
		label: "全部",
		value: ""
	},
	{
		value: "1",
		label: "上行"
	},
	{
		value: "2",
		label: "下行"
	}
];

const ScheduleShiftList = [
	{
		value: "1",
		label: "是"
	},
	{
		value: "0",
		label: "否"
	}
];
const props = {
	children: "children",
	label: "text",
	value: "id"
};
const checkedOption = [
	{
		name: "是",
		value: 1
	},
	{
		name: "否",
		value: 0
	}
];
const stateLiest = {
	operatingDivisions: [
		{
			label: "全部",
			value: ""
		},
		{
			label: "未发车",
			value: "0"
		},
		{
			label: "已发车",
			value: "1"
		},
		{
			label: "已完成",
			value: "2"
		}
	],
	fixedLine: [
		{
			label: "全部",
			value: ""
		},
		{
			label: "未发车",
			value: "0"
		},
		{
			label: "已发车",
			value: "1"
		},
		{
			label: "已完成",
			value: "2"
		}
	],
	noOperatingDivisions: [
		{
			label: "全部",
			value: ""
		},
		{
			label: "未完成",
			value: "0"
		},
		{
			label: "已完成",
			value: "2"
		}
	]
};
export default {
	data() {
		return {
			formData: {
				routeId: "",
				routeName: "",
				dir: "",
				vehicleNo: "",
				driverName: "",
				operateState: "",
				date: ""
			},
			activeName: "operatingDivisions",
			oldActiveName: "operatingDivisions",
			tabList: [],
			checkedOption,
			props,
			ScheduleShiftList,
			stateLiest,
			directionList,
			isInited: false,
			routeOptions: [],

			btnRoute: "/admin/operationalInformation"
		};
	},
	mixins: [btnMixins],
	components: {
		lineCondition,
		institutionsTree,
		operatingDivisions,
		fixedLine,
		noOperatingDivisions
	},
	created() {
		this.getDate();
		this.setActeiveName();
		this.getRouteList();
	},
	activated() {
		this.setActeiveName();
		this.getRouteList();
		//获取按钮权限
		this.$util.getButtons("/admin/operationalInformation").then(res => {
			console.log(res);
		});
	},
	watch: {
		$route: function () {
			this.setActeiveName();
		},
		activeName: function (val) {
			if (this.$route.name == "operationalInformation" || this.$route.name == "operationalInformationDefault") {
				this.oldActiveName = val;
			}
		}
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		// 查询默认当天
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			var nowDate = year + "-" + month + "-" + day;
			this.formData.date = nowDate;
		},
		setActeiveName() {
			let route = this.$route;
			if (route.query && route.query.type) {
				this.activeName = route.query.type;
			} else {
				this.activeName = this.oldActiveName;
			}
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formData = {
				routeId: "",
				routeName: "",
				dir: "",
				vehicleNo: "",
				driverName: "",
				operateState: "",
				date: ""
			};
		},
		oncheck() {
			let ref = this.$refs[this.activeName];
			ref.getList();
		},
		tabClick(e) {
			let url = "/admin/operationalInformation";
			this.$router.replace({ path: url, query: { type: e.name } });
		},
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutionsLine(data) {
			this.formData.routeId = data.id;
			this.formData.routeName = data.text;
			if (!this.isInited) {
				this.initFun();
			}
		},
		selectChange(val) {
			let name = "";
			this.routeOptions.forEach(item => {
				if (item.routeId == val) {
					name = item.name;
				}
			});
			this.formData.routeName = name;
			if (!this.isInited) {
				this.initFun();
			}
		},
		initFun() {
			this.$refs["operatingDivisions"].getList();
			this.$refs["fixedLine"].getList();
			this.$refs["noOperatingDivisions"].getList();
		}
	}
};
</script>
